import React from 'react'
import { setImage } from 'snippets'

export const Reiki = () => {
  return (
    <div className="container pt-4">
      <h2>Reiki</h2>
      <p>
        Reiki ist eine sehr alte Heilkunst, deren Ursprung unbekannt ist.
        Fünfzehntausend Jahre alte Höhlenmalereien zeigen bereits Fragmente
        dieser Technik. Sie war lange Zeit in Vergessenheit geraten. Gegen Ende
        des 19. Jahrhunderts wurde nach langen Studien und vielen Meditationen
        diese Technik von Dr. Mikao Usui, einem japanischen, christlichen Mönch,
        wiederentdeckt. So die Überlieferung.
      </p>

      <div className="d-flex">
        <img
          src={setImage('reiki.gif', '/static/media/images')}
          alt="Reiki"
          className="img-fluid"
          style={{
            height: '200px'
          }}
        />

        <table className="table table-borderless">
          <tr>
            <td>
              <span>Rei</span>
            </td>
            <td>bedeutet im japanischen universell und</td>
          </tr>

          <tr>
            <td>
              <span>Ki</span>
            </td>
            <td>bedeutet Kraft, Lebensenergie.</td>
          </tr>
        </table>
      </div>

      <p>
        Diese Lebenskraft wohnt in jedem Lebewesen inne, jedem Menschen, jedem
        Tier oder jeder Pflanze, unabhängig von Religion, Herkunft oder Rasse.
        Reiki ist die universelle Lebenskraft, der Ursprung allen Seins. Wir
        kommen schon mit dem Kanal für die fließende Reiki-Energie auf die Welt.
        Bei der ersten Zellteilung entsteht die Pranaröhre. Der Kanal für
        Energie. Naturvölker leben intuitiv mit dieser Energie.
      </p>

      <p>
        Da wir alle von dieser Reiki-Energie leben, ob wir dies nun bewusst oder
        unbewusst wahrnehmen, können wir diese Energie auch verstärkt aufnehmen.
        So wie wir meist unbewusst atmen und dies vollkommen automatisch
        geschieht, so nehmen wir Reiki in jeder Sekunde unseres Daseins
        automatisch durch unsere Energiekanäle auf.
      </p>

      <p>
        Die erste Sprache, die wir als Mensch erleben, ist die Sprache der
        Hände. Hände fangen uns bei unserer Geburt auf, sie halten uns, füttern
        uns, wickeln uns und wir lernen uns an diesen Händen zu orientieren.
      </p>

      <p>
        Durch die Hand fließt Lebensenergie. In unserer deutschen Muttersprache
        verweist das Wort be-hand-eln auf die Heilkraft der Hände.
        <br />
        So ist Reiki eine wunderbare Behandlungsmethode, da die Behandlung nur
        durch Handauflegen geschieht. Ebenso ist Reiki eine wirkungsvolle
        Selbsthilfetechnik, die zur vollkommenen Entspannung und zum Stressabbau
        dient und die Selbstheilungskräfte anregt.
      </p>

      <p>
        Da Reiki immer durch das Universum geführt wird, ist kein Schaden durch
        die Behandlung möglich. Der Therapeut ist immer nur Kanal für die Reiki
        – Energie.
      </p>

      <p>
        Als Ziel von Reiki wird immer eine ganzheitliche körperliche und
        seelische Heilung angegeben und diese harmonisiert mit allen
        Therapieformen, mit Methoden für persönliches Wachstum, Weltanschauungen
        und mit allen meditativen Techniken.
      </p>

      <p>
        Da es sich um eine universelle Kraft handelt, ist sie jedem Menschen
        verfügbar. Man braucht nur lernen, mit dieser Kraft umzugehen, um sie
        zum eigenen Nutzen oder Nutzen von anderen einzusetzen.
      </p>
    </div>
  )
}
