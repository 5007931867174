import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Home } from '../pages/Home'
import { NotFound } from '../pages/NotFound'
import { About } from '../pages/About'
import { Praxis } from '../pages/Praxis'
import { Reiki } from '../pages/Reiki'
import { WieWirktReiki } from '../pages/WieWirktReiki'
import { ReikiEinsatz } from '../pages/ReikiEinsatz'
import { ReikiNebenwirkungen } from '../pages/ReikiNebenwirkungen'
import { ReikiSitzung } from '../pages/ReikiSitzung'
import { Ausbildung } from '../pages/Ausbildung'
import { Buchung } from '../pages/Buchung'
import { Impressum } from '../pages/Impressum'
import { Sitemap } from '../pages/SiteMap'

export const Routing = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/reiki/reiki" element={<Reiki />} />
      <Route exact path="/reiki/wie-wirkt-reiki" element={<WieWirktReiki />} />
      <Route exact path="/reiki/einsatz-von-reiki" element={<ReikiEinsatz />} />
      <Route
        exact
        path="/reiki/nebenwirkungen"
        element={<ReikiNebenwirkungen />}
      />
      <Route exact path="/reiki/sitzung" element={<ReikiSitzung />} />
      <Route exact path="/ausbildung" element={<Ausbildung />} />
      <Route exact path="/praxis" element={<Praxis />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/buchung" element={<Buchung />} />

      <Route exact path="/impressum" element={<Impressum />} />
      <Route exact path="/sitemap" element={<Sitemap />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
