import React from 'react'
import { Link } from 'react-router-dom'

export const Sitemap = () => {
  return (
    <div className="container pt-4">
      <h3 className="text-primary">Sitemap</h3>

      <div id="sitemap">
        <ul className="text-info text-decoration-none">
          <li className="text-info text-decoration-none">
            <Link to="/" className="text-info text-decoration-none">
              Home
            </Link>
          </li>

          <li className="text-info text-decoration-none">
            <Link to="/about" className="text-info text-decoration-none">
              Über mich
            </Link>
          </li>

          <li className="text-info text-decoration-none">
            <Link to="/praxis" className="text-info text-decoration-none">
              Paxis
            </Link>
          </li>

          <li className="text-info text-decoration-none">
            Reiki
            <ul className="text-info text-decoration-none">
              <li>
                <Link
                  className="text-info text-decoration-none"
                  to="/reiki/reiki">
                  Reiki
                </Link>
              </li>
              <li>
                <Link
                  className="text-info text-decoration-none"
                  to="/reiki/wie-wirkt-reiki">
                  Wie wirkt Reiki?
                </Link>
              </li>
              <li>
                <Link
                  className="text-info text-decoration-none"
                  to="/reiki/einsatz-von-reiki">
                  Einsatz von Reiki
                </Link>
              </li>
              <li>
                <Link
                  className="text-info text-decoration-none"
                  to="/reiki/nebenwirkungen">
                  Nebenwirkungen
                </Link>
              </li>
              <li>
                <Link
                  className="text-info text-decoration-none"
                  to="/reiki/sitzung">
                  Reiki-Sitzung
                </Link>
              </li>
            </ul>
          </li>

          <li className="text-info text-decoration-none">
            <Link to="/ausbildung" className="text-info text-decoration-none">
              Reiki-Ausbildung
            </Link>
          </li>

          <li className="text-info text-decoration-none">
            <Link to="/buchung" className="text-info text-decoration-none">
              Buchung
            </Link>
          </li>

          <li className="text-info text-decoration-none">
            <Link className="text-info text-decoration-none" to="/impressum">
              Datenschutz
            </Link>
          </li>
          <li className="text-info text-decoration-none">
            <Link className="text-info text-decoration-none" to="/impressum">
              Impressum
            </Link>
          </li>
          <li className="text-info text-decoration-none">
            <Link className="text-info text-decoration-none" to="/sitemap">
              Sitemap
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
