import React from 'react'

export const ReikiEinsatz = () => {
  return (
    <div className="container pt-4">
      <h2>Wo kann Reiki eingesetzt werden?</h2>

      <div
        id="wellness"
        style={{
          marginLeft: '2rem'
        }}>
        <h5 className="text-uppercase">Reiki und Wellness:</h5>

        <ul>
          <li>
            Reiki bringt Körper, Geist und Seele im ganzheitlichen Sinne in
            Gleichklang und Harmonie
          </li>
          <li>Reiki fördert die natürliche Selbstheilung</li>
          <li>Reiki vitalisiert Körper und Geist</li>
          <li>Reiki fördert die tiefe Entspannung</li>
        </ul>
      </div>

      <div
        id="wohlfuehlaspekt"
        style={{
          marginLeft: '2rem'
        }}>
        <h5 className="text-uppercase">Reiki und der Wohlfühlaspekt:</h5>

        <ul>
          <li>Reiki fördert die natürliche Selbstheilung</li>
          <li>Reiki hilft Ängste überwinden</li>
          <li>Reiki unterstützt die Lösung von Blockaden</li>
          <li>
            Reiki wirkt durch die Stärkung der Abwehrkräfte vorbeugend gegen
            Krankheiten
          </li>
          <li>Reiki fördert Talente und Kreativität</li>
          <li>Reiki bringt tieferes Verständnis für sich selbst und andere</li>
          <li>Reiki stärkt das Selbstbewusstsein</li>
        </ul>
      </div>

      <div
        id="therapie"
        style={{
          marginLeft: '2rem'
        }}>
        <h5 className="text-uppercase">
          Reiki kann bei vielen Beschwerden therapiebegleitend eingesetzt
          werden:
        </h5>

        <ul>
          <li>
            Insbesondere bei psychosomatischen Beschwerden:
            <ul>
              <li>Bei chronischen Schmerzen</li>
              <li>Bei Verdauungsstörungen</li>
              <li>Bei Muskelverspannungen</li>
              <li>Bei Nervosität und Angstzuständen</li>
              <li>Bei Konzentrationsschwierigkeiten</li>
            </ul>
          </li>
          <li>Reiki reduziert die Nebenwirkungen bei Chemotherapien</li>
          <li>Reiki unterstützt bei Schwangerschaft und Geburt</li>
          <li>Reiki wirkt besonders hilfreich vor und nach Operationen</li>
          <li>
            Reiki lindert Schmerzen bei akuten und chronischen Krankheiten
          </li>
          <li>Reiki hilft entgiftend auf Körper und Geist</li>
          <li>Reiki unterstützt auch andere Therapieformen</li>
        </ul>
      </div>

      <h3 className="text-center mx-auto">
        Reiki hilft auch kranken und verletzten Tieren
      </h3>
    </div>
  )
}
