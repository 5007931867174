import './App.css'

import { BrowserRouter as Router } from 'react-router-dom'
import { ScrollTopButton } from './components/ScrollTopButton'

import { Nav } from './components/Nav'
import { Routing } from './components/Routing'
import { Footer } from './components/Footer'
import { Kontakt } from './components/Kontakt'

function App() {
  return (
    <Router>
      <header>
        <Nav />
      </header>

      <main className="flex-shrink-0">
        <Routing />
      </main>

      <footer className="mt-auto pt-4">
        <Kontakt />

        <Footer />
      </footer>

      <ScrollTopButton />
    </Router>
  )
}

export default App
