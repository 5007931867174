import React, { useState } from 'react'

export const ScrollTopButton = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <i
      className="bi bi-arrow-up-square text-dark"
      onClick={() => scrollToTop()}
      style={{
        display: visible ? 'inline' : 'none',
        position: 'fixed',
        right: '50px',
        bottom: '90px',
        fontSize: '3rem',
        zIndex: 1,
        cursor: 'pointer'
      }}
    />
  )
}
