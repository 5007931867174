import React from 'react'
import { setImage } from 'snippets'

export const About = () => {
  return (
    <div className="container pt-4">
      <div className="row gap-1">
        <div className="col-md">
          <img
            src={setImage('katharina-maya-1.jpg', '/static/media/images')}
            alt="Foto Katharina Maya"
            className="float-md-end img-fluid"
            style={{
              objectFit: 'contain',
              maxHeight: '500px'
            }}
          />
        </div>

        <div className="col-md-7">
          <h3>Katharina Maya</h3>

          <p>
            Reiki begleitet mich gefühlt schon mein ganzes Leben.
            <br />
            Bereits mein Papa hat andere Menschen mit den Händen geheilt.
            <br />
            Ich war fasziniert davon, wie er{' '}
            <span>Schmerzen und emotionale Leiden</span> mit dem simplen
            Auflegen seiner Hände auflösen konnte.
          </p>

          <p>
            Ich wuchs in München auf.
            <br />
            Bereits mit 6 Jahren lernte ich Meditationen und das Heilen.
            <br />
            Mit 34 machte ich meine Ausbildung zur Reiki Meisterin.
            <br />
            Nebenbei fing ich an, als Bewusstseinscoach online und Praxisnah zu
            arbeiten: Meine spirituellen Interessen haben mich in allen Zügen
            positiv unterstützt und mich zu einem glücklicheren und
            selbstbewussten Menschen gemacht.
          </p>

          <p>
            Ich habe alles aufgegeben und zog mich zurück. Ich meditierte,
            wanderte und arbeitete an meinen emotionalen Themen und erlangte so
            immer mehr Bewusstsein zu mir selbst.
            <br />
            In dieser Zeit traf ich meine Reiki-Ausbilderin, die mich in allen 3
            Reiki-Graden ausbildete.
          </p>
        </div>
      </div>
    </div>
  )
}
