import React from 'react'

export const ReikiSitzung = () => {
  return (
    <div className="container pt-4">
      <h2>Reiki-Sitzung</h2>

      <p>
        Beim dem Klang sanfter Musik darfst DU dich tief im Liegen entspannen,
        alle Sorgen und Probleme vergessen und dich mit Energie auftanken
        lassen.
      </p>

      <p>
        Wer eine Reiki-Anwendung dazu nutzen möchte, etwas für sein Wohlbefinden
        zu tun, dem genügen 1-2 Anwendungen.
      </p>

      <p>
        Bei gesundheitlichen Problemen empfiehlt es sich Anwendungen an drei bis
        fünf aufeinander folgenden Tagen durchzuführen und danach 1-2 mal die
        Woche.
      </p>
    </div>
  )
}
