import React from 'react'

export const WieWirktReiki = () => {
  return (
    <div className="container pt-4 text-center">
      <div className="w-75 m-auto">
        <h2>Wie wirkt Reiki?</h2>

        <p className="w-75 m-auto">
          Die Anregung der Selbstheilung durch Lebensenergie (Qi) kennt viele
          unterschiedliche Formen.
        </p>

        <p className="mt-2">
          <span className="w-75 m-auto">Reiki ist eine davon!</span>
        </p>

        <p>
          Die Funktionsweise von Reiki, basiert auch wie die anderen Formen der
          Energiearbeit auf dem Prinzip der Schwingungsänderung.
        </p>

        <p>
          Eine Krankheit ist für den Körper nichts anderes als ein
          disharmonischer Zustand im Energiesystem. Durch die Gabe von
          universeller Energie (Reiki) wird dieser disharmonische Zustand in
          eine harmonische Schwingung zurückgeführt.
        </p>

        <p>
          Je nachdem welche Krankheit und die damit verbunden Umstände bei der
          betroffenen Person vorherrschen, geschieht dies unterschiedlich
          schnell bzw. mit unterschiedlichem Erfolg.
        </p>

        <p>
          Neben der selbstheilenden Wirkung, die von Reiki ausgeht, fördert
          Reiki auch die allgemeine Entspannung.
        </p>

        <p>
          Bei einer Reiki-Behandlung fließt universelle Lebensenergie durch die
          Hände des Behandlers zum Klienten. Dadurch wird der ganze Körper des
          Klienten an verschiedenen Punkten stimuliert.
        </p>

        <p>
          Der Empfänger reagiert und empfindet dabei individuell verschieden.
          Meistens stellt sich eine tiefe Entspannung ein, die nicht selten in
          einen erholsamen Schlaf mündet. Dieser beeinträchtigt die Wirkung
          nicht, sondern kann sie sogar fördern. Dabei können alte und
          unverarbeitete Erlebnisse ins Bewusstsein kommen und Emotionen werden
          frei.
        </p>
      </div>
    </div>
  )
}
