import React from 'react'

export const ReikiNebenwirkungen = () => {
  return (
    <div className="container pt-4">
      <h2>Nebenwirkungen und Vorsichtsmaßnahmen</h2>

      <div
        id="nebenwirkungen"
        style={{
          marginLeft: '2rem'
        }}>
        <h5>Es sind keine Nebenwirkungen bekannt</h5>

        <p>
          Reiki kann auch problemlos mit einer schulmedizinischen Behandlung
          oder anderen komplementärmedizinischen Methoden kombiniert werden.
          <br />
          Reiki ersetzt aber keine Behandlung beim Arzt, es unterstützt jedoch
          jede medizinische Therapie.
        </p>
      </div>
    </div>
  )
}
