import { NavLink } from 'react-router-dom'
import { setImage } from 'snippets'
import { bootstrapNavCloser } from 'snippets'

export const Nav = () => {
  bootstrapNavCloser()

  return (
    <nav className="navbar navbar-light navbar-expand-md bg-primary fixed-top">
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <img
            src={setImage('/logo-reiki.png', '/static/media/images')}
            alt="Logo"
            style={{
              width: '60px',
              overflow: 'hidden'
            }}
          />
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mainNav"
          aria-controls="mainNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse navbar-nav" id="mainNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink
                to="/"
                className={(isActive) =>
                  'nav-link' + (!isActive ? ' unselected' : '')
                }
                aria-current="page"
                style={({ isActive }) => ({
                  color: isActive ? 'var(--bs-secondary)' : '',
                  textShadow: isActive
                    ? '0 0 1px var(--bs-secondary), 0 0 1px var(--bs-secondary)'
                    : ''
                })}>
                Home
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/about"
                className={(isActive) =>
                  'nav-link' + (!isActive ? ' unselected' : '')
                }
                aria-current="page"
                style={({ isActive }) => ({
                  color: isActive ? 'var(--bs-secondary)' : '',
                  textShadow: isActive
                    ? '0 0 1px var(--bs-secondary), 0 0 1px var(--bs-secondary)'
                    : ''
                })}>
                Über mich
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/praxis"
                className={(isActive) =>
                  'nav-link' + (!isActive ? ' unselected' : '')
                }
                aria-current="page"
                style={({ isActive }) => ({
                  color: isActive ? 'var(--bs-secondary)' : '',
                  textShadow: isActive
                    ? '0 0 1px var(--bs-secondary), 0 0 1px var(--bs-secondary)'
                    : ''
                })}>
                Praxis
              </NavLink>
            </li>

            <li className="nav-item dropdown">
              <NavLink
                className={(isActive) =>
                  'nav-link dropdown-toggle' + (!isActive ? ' unselected' : '')
                }
                to="/reiki"
                id="reiki-dropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={({ isActive }) => ({
                  color: isActive ? 'var(--bs-secondary)' : '',
                  textShadow: isActive
                    ? '0 0 1px var(--bs-secondary), 0 0 1px var(--bs-secondary)'
                    : ''
                })}>
                Reiki
              </NavLink>
              <ul
                className="dropdown-menu dropdown-menu dropdown-menu-end bg-primary border-2 border-secondary"
                aria-labelledby="reiki-dropdown">
                <li>
                  <NavLink className="dropdown-item" to="/reiki/reiki">
                    Reiki
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="/reiki/wie-wirkt-reiki">
                    Wie wirkt Reiki?
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to="/reiki/einsatz-von-reiki">
                    Einsatz von Reiki
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/reiki/nebenwirkungen">
                    Nebenwirkungen
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/reiki/sitzung">
                    Reiki-Sitzung
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <NavLink
                to="/ausbildung"
                className={(isActive) =>
                  'nav-link' + (!isActive ? ' unselected' : '')
                }
                aria-current="page"
                style={({ isActive }) => ({
                  color: isActive ? 'var(--bs-secondary)' : '',
                  textShadow: isActive
                    ? '0 0 1px var(--bs-secondary), 0 0 1px var(--bs-secondary)'
                    : ''
                })}>
                Reiki-Ausbildung
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/buchung"
                className={(isActive) =>
                  'nav-link' + (!isActive ? ' unselected' : '')
                }
                aria-current="page"
                style={({ isActive }) => ({
                  color: isActive ? 'var(--bs-secondary)' : '',
                  textShadow: isActive
                    ? '0 0 1px var(--bs-secondary), 0 0 1px var(--bs-secondary)'
                    : ''
                })}>
                Buchung
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
