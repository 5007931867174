import React from 'react'
import { setImage } from 'snippets'

export const Home = () => {
  return (
    <div className="container pt-4 d-flex flex-column justify-content-center align-items-center">
      <div className="text-logo-container">
        <img
          src={setImage('wolke-schmetterling.png', '/static/media/images')}
          alt="Logo"
          className="text-logo img-fluid mb-5"
          style={{
            opacity: '.5'
          }}
        />
        <span className="text-logo-caption">
          <h4>Liebe Seele!</h4>
          <p>Wie schön, dass dich Dein Weg zu mir geführt hat.</p>
          <p>
            Die persönliche Weiterentwicklung und vor allem das Erlangen der
            Erkenntnis, dass das Leben weitaus mehr beinhaltet als "Struktur",
            ist keinesfalls immer leicht.
          </p>
          <p>
            Gerade die Entwicklung der Sensibilität für DICH selbst und all das,
            was dich umgibt ist deine größte Stärke. Je mehr Fassaden um dich
            herum brechen, desto mehr wirst du spüren, dass es höchste Zeit ist,
            etwas für DICH zu tun!
          </p>
          <p>
            Ich begleite dich sehr gerne dabei, dein volles Potential zu
            entfalten.
          </p>
        </span>
      </div>

      <div className="w-75 mx-auto text-center">
        <h2>Reiki-Ausbildung (Übersicht)</h2>

        <p>
          In meiner Reiki-Ausbildung lernst Du, wie Du Dich bewusst mit der{' '}
          <span>universellen Lebensenergie</span> verbindest.
        </p>

        <p>
          Du erlangst durch die Symboleinweihungen in jedem Grad einen{' '}
          <span>besseren, bewussteren Zugang</span>, sodass Deine eigenen
          Schwingung sich erhöht und Du besser mit anderen arbeiten kannst, ohne
          selbst krank zu werden.
        </p>

        <p>
          Du lernst, andere Menschen in die Reiki-Technik{' '}
          <i className="bi bi-dash-lg" /> als Reiki Lehrer / Meister{' '}
          <i className="bi bi-dash-lg" /> einzuweihen und unterstützt sie in
          ihrer Entwicklung.
        </p>
      </div>
    </div>
  )
}
