import React from 'react'

export const Kontakt = () => {
  const landesvorwahl = '+49'
  const vorwahl = '0152'
  const phoneNumber = '07894062'

  const email = 'info@reiki-uphusum.de'

  return (
    <div className="bg-secondary kontakt">
      <div className="container text-white d-flex flex-column align-items-center">
        <div
          className="card bg-body border-1 border-primary mb-3 text-center"
          style={{
            maxWidth: '100vw',
            width: '350px',
            height: '150px'
          }}>
          <div className="card-header pb-0">
            <h4 className="text-primary text-uppercase">
              Reiki-Praxis Uphusum
            </h4>
          </div>
          <div className="card-body text-primary">
            <h5 className="card-title">Katharina Maya</h5>
            <div className="card-text">
              <a
                className="text-info text-decoration-none"
                href={`tel:${
                  landesvorwahl + vorwahl.slice(1, vorwahl.length) + phoneNumber
                }`}>
                {vorwahl} - {phoneNumber}
              </a>
              <br />
              <a
                className="text-info text-decoration-none"
                href={`mailto:${email}?subject=Kontaktanfrage`}
                target="_blank"
                rel="noreferrer">
                {email}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
