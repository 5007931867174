import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <div
      className="bg-dark footer"
      style={{
        fontSize: '.8rem'
      }}>
      <div className="container d-flex justify-content-between text-white">
        <p>&copy; Reiki Uphusum</p>

        <span className="fw-normal">
          <Link className="text-decoration-none px-2" to="/sitemap">
            Sitemap
          </Link>
          <Link className="text-decoration-none px-2" to="/impressum">
            Datenschutz
          </Link>
          <Link className="text-decoration-none px-2" to="/impressum">
            Impressum
          </Link>
        </span>
      </div>
    </div>
  )
}
