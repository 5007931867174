import React from 'react'
import { setImage } from 'snippets'

export const Praxis = () => {
  return (
    <div className="container pt-4">
      <div className="d-flex flex-column align-items-center">
        <h2>Meine Praxisräume</h2>

        <img
          src={setImage('praxis-1.jpeg', '/static/media/images/praxis')}
          alt=""
          className="img-fluid mb-4"
          style={{
            maxWidth: '750px',
            objectFit: 'contain'
          }}
        />
        <img
          src={setImage('praxis-2.jpeg', '/static/media/images/praxis')}
          alt=""
          className="img-fluid"
          style={{
            maxWidth: '750px',
            objectFit: 'contain'
          }}
        />
      </div>
    </div>
  )
}
