import React from 'react'

export const Buchung = () => {
  return (
    <div className="container pt-4">
      <iframe
        frameBorder="0"
        src="https://meetfox.com/de/e/katharina-maya/borderless"
        scrolling="no"
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '855px'
        }}
      />
    </div>
  )
}
