import React from 'react'

export const Ausbildung = () => {
  return (
    <div className="container pt-4">
      <div className="head">
        <p className="fs-4 text-center">
          <span className="farbverlauf">Ausbildung</span> für Menschen, die{' '}
          <span className="farbverlauf">neue Wege</span> gehen wollen
        </p>

        <p className="text-center">
          Für alle, die das Wissen und die <span>Energien der neuen Zeit</span>{' '}
          in ihr System integrieren und wahre <span>Gesundheit</span> über den
          Kontakt zur <span>Seele</span> erreichen wollen.
        </p>
      </div>

      <hr className="my-5" />

      <div className="d-flex flex-column align-items-center">
        <h1>Reiki Ausbildungen</h1>
        <p
          className="text-muted"
          style={{
            fontSize: '.8rem'
          }}>
          (Alle Preise inkl. 19% MwSt)
        </p>
      </div>

      <div id="grad-1" className="d-flex flex-column align-items-center">
        <div
          className="card text-center w-75 mb-5"
          style={{
            maxWidth: '600px'
          }}>
          <div className="card-header">
            <h4>Reiki Grad 1</h4>
          </div>
          <div className="card-body">
            <p className="card-title fs-5 fw-bold">
              Einzel- oder Gruppenausbildung
            </p>
            <blockquote className="blockquote mb-0">
              <p>2 Tage</p>
              <footer className="blockquote-footer">
                inkl. Workbook und Zertifikat (Online oder in der Praxis)
              </footer>
            </blockquote>
          </div>
          <div className="card-footer fs-5">
            Einzelausbildung: <span>450 €</span>
            <br />
            Gruppenausbildung: <span>380 €</span>
          </div>
        </div>

        <div
          className="card text-center w-75 mb-5"
          style={{
            maxWidth: '600px'
          }}>
          <div className="card-header">
            <h4>Reiki Grad 2</h4>
          </div>
          <div className="card-body">
            <p className="card-title fs-5 fw-bold">
              Einzel- oder Gruppenausbildung
            </p>
            <blockquote className="blockquote mb-0">
              <p>2 Tage</p>
              <footer className="blockquote-footer">
                inkl. Workbook und Zertifikat (Online oder in der Praxis)
              </footer>
            </blockquote>
          </div>
          <div className="card-footer fs-5">
            Einzelausbildung: <span>600 €</span>
            <br />
            Gruppenausbildung: <span>550 €</span>
          </div>
        </div>

        <div
          className="card text-center w-75"
          style={{
            maxWidth: '600px'
          }}>
          <div className="card-header">
            <h4>Reiki Grad 3 (Meister)</h4>
          </div>
          <div className="card-body">
            <p className="card-title fs-5 fw-bold">
              Einzel- oder Gruppenausbildung
              <br />
              <span
                className="text-muted"
                style={{
                  fontSize: '.8rem',
                  color: 'black !important'
                }}>
                (Gruppe max. 4 Teilnehmer)
              </span>
            </p>
            <blockquote className="blockquote mb-0">
              <p>
                ca. 8 Wochen (online)
                <br />2 Tage (in der Praxis)
              </p>
              <footer className="blockquote-footer">
                inkl. Workbook, Script und Zertifikat (Online oder in der
                Praxis)
              </footer>
            </blockquote>
          </div>
          <div className="card-footer fs-5">
            Einzelausbildung: <span>1450 €</span>
            <br />
            Gruppenausbildung: <span>1300 €</span>
          </div>
        </div>
      </div>
    </div>
  )
}
